<template>
  <div>
    <div class="banner" id="investor_banner1" :style="{backgroundImage:('url('+banner+')')}">
        <!-- <img :src="banner" /> -->
        <span
          >{{ $t("indexnav.Investor Relations")
          }}<i
            :style="{
              display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
            }"
            >Investor Relations</i
          ></span
        >
    </div>

    <section class="ab-item1">
      <!-- <a href="/">{{ $t("indexnav.Home") }}</a>
      <a
        v-for="(item, index) in setCrumbs($route.fullPath)"
        :key="index"
        :href="item.crumb"
      >
       <span> ></span> {{ item[$store.state.navLanguageKey] }}
      </a> -->
      <bread-crumb />
    </section>

    <figure>
      <div>
        <!-- 公司资料 -->
        <router-link to="/investors/companyprofile"
          >{{ $t("indexnav.Corporate Information") }}
        </router-link>
        <!-- 公告及通函 -->
        <router-link to="/investors/notice">
          {{ $t("indexnav.Announcements and Circulars") }}
        </router-link>
        <!-- 上市文件 -->
        <router-link to="/investors/listingdocument"
          >{{ $t("indexnav.Listing Documents") }}
        </router-link>
        <!-- 财务报告 -->
        <router-link to="/investors/financialreports">{{
          $t("indexnav.Financial Reports")
        }}</router-link>
        <!-- 公司推介材料 -->
        <router-link to="/investors/materials">{{
          $t("indexnav.Corporate Presentation")
        }}</router-link>
        <!-- 股票信息 -->
        <router-link to="/investors/stockmsg">
          {{ $t("indexnav.Stock Chart") }}
        </router-link>
        <!-- 投资者关系日历 -->
        <router-link to="/investors/investorcalendar">{{
          $t("indexnav.IR Calendar")
        }}</router-link>
        <!-- 新闻稿 -->
        <router-link to="/investors/newsrelease">
          {{ $t("indexnav.Press Release") }}
        </router-link>
        <!-- 企业管治 -->
        <router-link to="/investors/corporategovernance">{{
          $t("indexnav.Corporate Governance")
        }}</router-link>
        <!-- 企业社会责任  -->
        <router-link to="/aboutus/responsibility"
          >{{ $t("indexnav.CSR") }}
        </router-link>
        <!-- 邮件订阅 -->
        <router-link to="/investors/email">
          {{ $t("indexnav.Email Subscription") }}</router-link
        >
        <!-- 投资者查询 -->
        <router-link to="/investors/query"
          >{{ $t("indexnav.Investor Enquiries") }}
        </router-link>
      </div>
    </figure>
  </div>
</template>

<script>
import { PageType } from "@/utils/constant";
import { getAction } from "@/api/manage";

export default {
  data() {
    return {
      banner: "",
    };
  },
  created() {
    // 初始化banner
    let that = this;
    getAction("/jeecg-system/common/frontend/advertise/active_list", {
      typeCode: PageType.TOUZIZHEGUANXI,
    }).then((res) => {
      if (
        res.result.records instanceof Array &&
        res.result.records.length > 0
      ) {
         if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg;
        }else{
        that.banner = res.result.records[0].sampleImg
        }
      }
    });
  },
};
</script>

<style scoped>
header + a img {
  max-width: 100%;
  width: 100%;
}
.banner {
  position: relative;
}
.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}

section,
figure {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.ab-item1 {
  padding: 0 0 1.5em 0;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1.5rem;
  color: #595757;
}

figure div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
}

figure div a {
  display: flex;
  justify-items: center !important;
  width: 32%;
  margin-right: 2%;
  background: #2e466f;
  border-radius: 10px;
  line-height: 3em;
  font-size: 1.4em;
  color: #fff;
  justify-content: center;
  margin-bottom: 1.5em;
}

figure div a:nth-child(3),
figure div a:nth-child(6),
figure div a:nth-child(9),
figure div a:nth-child(12) {
  margin-right: 0;
}
figure div a:hover {
  color: red;
}

@media screen and (max-width: 1200px) {
  .ab-item1 {
    font-size: 1.2em;
    padding: 0 1.5em 1.5em 1.5em;
  }

  figure {
    padding: 0 1.5em 1.5em 0.7em;
  }
}

@media screen and (max-width: 768px) {
  figure div a {
    width: 100%;
    margin-right: 0;
  }
}
</style>
